import React, { useEffect, useState } from "react";
import CompanyViewDesktop from "bundles/common/components/Desktop/CompanyViewDesktop";
import CompanyViewMobile from "bundles/common/components/Mobile/CompanyViewMobile";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import sh from "../utils/sh";
import { incrementJobOfferMetric, incrementWebsiteClickCount } from "bundles/common/utils/utils";
import { sortDateRange } from "bundles/common/utils/utils";


const CompanyView = ({ company, jobCount, jobOffers, projects}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const useQuery = () => {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();
  const i18nLocale = useSelector((state) => state.i18nLocale);

  const currentCandidate = useSelector((state) => state.current_candidate);
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const authenticityToken = useSelector((state) => state.authenticity_token);

  const [authenticationScope, setAuthenticationScope] = useState(null);
  const [isLoginModalOpened, setIsLoginModalOpened] = useState(false);
  const [isSpontaneous, setIsSpontaneous] = useState(false);
  const [loginToAddFavorite, setLoginToAddFavorite] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isApplying, setIsApplying] = useState(
    query.get("isApplying") === "true" || false
  );
  const [handleJob, setHandleJob] = useState(false);
  const [tabActive, setTabActive] = useState(
    query.get("selectedTab") || "about"
  );

  const jobApplications = currentCandidate?.job_applications?.find(
    (jobApplication) =>
      !jobApplication.job_offer_id && jobApplication.company_id == company.id
  );

  const handleSpontaneousApplyClick = () => {
    if (currentCandidate?.id) {
      setIsApplying(true);
    } else {
      setAuthenticationScope(`spontaneous_${company.id}_S`);
      setIsSpontaneous(true);
      setIsLoginModalOpened(true);
    }
  };

  function handleChangeTab(event, newValue) {
    setTabActive(newValue);
  }

  const handleOfferClicked = (offer, e, addFavorite = false) => {
    if (currentCandidate?.id) {
      setSelectedJob(offer);
      setHandleJob(true);
    } else {
      setAuthenticationScope(`application_${offer.id}_S`);
      setLoginToAddFavorite(addFavorite);
      setIsLoginModalOpened(true);
    }
  };

  const onLoginModalCloses = () => {
    let newURL = window.location.origin + window.location.pathname;

    if (authenticationScope.includes("spontaneous")) {
      newURL += "?isApplying=true";
    } else if (authenticationScope.includes("application")) {
      newURL += "?selectedTab=jobs";
    } else {
      newURL += window.location.search;
    }

    window.location.href = newURL;
  };


  useEffect(() => {
    if (!selectedJob) {
      setSelectedJob(jobOffers?.length > 0 ? jobOffers[0] : null);
    }
  }, [jobOffers?.length]);

  useEffect(() => {
    if (selectedJob?.id) {
      incrementJobOfferMetric(selectedJob, "view_count", authenticityToken);
    }
  }, [selectedJob?.id]);

  useEffect(() => {
    if (query.get("jid")) {
      sh.get(`/${i18nLocale}/candidate/job_offers/${query.get("jid")}`, {
        authenticity_token: authenticityToken,
      })
        .then((res) => {
          if (res?.data?.data) setSelectedJob(res?.data?.data);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {});
    }
  }, [jobOffers?.length]);

  const sortedProjects = sortDateRange(projects);

  return (
    <>
      {isDesktop
        ?
        <CompanyViewDesktop
          company={company}
          jobCount={jobCount}
          jobOffers={jobOffers}
          projects={projects}
          currentCandidate={currentCandidate}
          currentRecruiter={currentRecruiter}
          handleSpontaneousApplyClick={handleSpontaneousApplyClick}
          setIsApplying={setIsApplying}
          isApplying={isApplying}
          jobApplications={jobApplications}
          tabActive={tabActive}
          handleChangeTab={handleChangeTab}
          sortedProjects={sortedProjects}
          handleOfferClicked={handleOfferClicked}
          selectedJob={selectedJob}
          handleJob={handleJob}
          setHandleJob={setHandleJob}
          setSelectedJob={setSelectedJob}
          isLoginModalOpened={isLoginModalOpened}
          onLoginModalCloses={onLoginModalCloses}
          authenticationScope={authenticationScope}
          isSpontaneous={isSpontaneous}
          loginToAddFavorite={loginToAddFavorite}
          incrementWebsiteClickCount={incrementWebsiteClickCount}
          authenticityToken={authenticityToken}
        />
        :
        <CompanyViewMobile
          company={company}
          jobCount={jobCount}
          jobOffers={jobOffers}
          projects={projects}
          currentCandidate={currentCandidate}
          currentRecruiter={currentRecruiter}
          handleSpontaneousApplyClick={handleSpontaneousApplyClick}
          setIsApplying={setIsApplying}
          isApplying={isApplying}
          jobApplications={jobApplications}
          tabActive={tabActive}
          handleChangeTab={handleChangeTab}
          sortedProjects={sortedProjects}
          handleOfferClicked={handleOfferClicked}
          selectedJob={selectedJob}
          handleJob={handleJob}
          setHandleJob={setHandleJob}
          setSelectedJob={setSelectedJob}
          isLoginModalOpened={isLoginModalOpened}
          onLoginModalCloses={onLoginModalCloses}
          authenticationScope={authenticationScope}
          isSpontaneous={isSpontaneous}
          loginToAddFavorite={loginToAddFavorite}
          incrementWebsiteClickCount={incrementWebsiteClickCount}
          authenticityToken={authenticityToken}
        />
      }
    </>
  );
};

export default CompanyView;
