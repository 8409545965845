import React from "react";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ChatIcon from "@mui/icons-material/Chat";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import FeedIcon from "@mui/icons-material/Feed";

export const mobileUnauthMenus = [
  {
    titleId: "nav.faq",
    target: "faq",
    icon: () => <HelpOutlineIcon style={{ width: "18px", height: "18px" }} />,
  },
  {
    titleId: "menu.blog_posts",
    target: "candidates/posts",
    icon: () => <FeedIcon style={{ width: "18px", height: "18px" }} />,
  },
  {
    titleId: "nav.contact",
    target: "contact_us",
    icon: () => <ChatIcon style={{ width: "18px", height: "18px" }} />,
  },
  {
    titleId: "nav.candidate.go_recruiter",
    logout: true,
    target: "recruiter",
    icon: () => <SwapHorizIcon style={{ width: "18px", height: "18px" }} />,
  },
];

export const mobileAuthenticatedSubmenus = [
  {
    code: "help",
    titleId: "nav.help",
    links: [
      {
        titleId: "nav.faq",
        target: "candidate/faq",
        icon: () => (
          <HelpOutlineIcon style={{ width: "18px", height: "18px" }} />
        ),
      },
      {
        titleId: "nav.contact",
        target: "candidate/contact_us",
        icon: () => <ChatIcon style={{ width: "18px", height: "18px" }} />,
      },
      {
        titleId: "nav.candidate.go_recruiter",
        logout: true,
        target: "recruiter",
        icon: () => <SwapHorizIcon style={{ width: "18px", height: "18px" }} />,
      },
    ],
    isOpen: false,
  },
];

export const desktopAuthenticatedSubmenus = [
  {
    titleId: "nav.help",
    links: [
      {
        titleId: "nav.faq",
        target: "candidate/faq",
        icon: () => (
          <HelpOutlineIcon style={{ width: "18px", height: "18px" }} />
        ),
      },
      {
        titleId: "nav.contact",
        target: "candidate/contact_us",
        icon: () => <ChatIcon style={{ width: "18px", height: "18px" }} />,
      },
      {
        titleId: "nav.candidate.go_recruiter",
        target: "recruiter",
        logout: true,
        icon: () => <SwapHorizIcon style={{ width: "18px", height: "18px" }} />,
      },
    ],
  },
];
