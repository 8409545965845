import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { useSnackbar } from "notistack";
import {
  Box,
  Typography,
  Modal,
  Grid,
  Paper,
  Button,
  Link,
  Popover,
  FormControl,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
  TextField,
} from "@mui/material";
import { UnarchiveOutlined } from "@mui/icons-material";
import { makeStyles, useTheme } from "@mui/styles";
import CandidateSummary from "./CandidateSummary";
import sh from "bundles/common/utils/sh";
import { getFormattedDate } from "bundles/common/utils/utils";
import NewApplication from "icons/new-application.svg";
import { replaceCurrentRecruiter } from "bundles/Recruiter/constants/recruiterConstants.js";

const CandidateDialog = ({
  candidate,
  jobApplication,
  setJobApplication,
  setIndexStatus,
  isDialogOpen,
  setIsDialogOpen,
}) => {
  const authenticityToken = useSelector((state) => state.authenticity_token);
  const theme = useTheme();
  const dispatch = useDispatch();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles((theme) => ({
    modalContainer: {
      marginLeft: isDesktop ? "64px" : "16px",
      marginRight: isDesktop ? "64px" : "16px",
      height: isDesktop ? "calc(100% - 128px)" : "calc(100% - 32px)",
      overflow: "hidden",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    modalBox: {
      maxHeight: "100%",
      display: "flex",
    },
    applicationAction: {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
      padding: "16px 32px",
      maxHeight: "100%",
      overflow: "auto",
    },
    candidateSummary: {
      borderLeft: `2px solid ${theme.palette.common.sh_lightgrey}`,
      maxHeight: "100%",
      overflow: "auto",
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
      display: "flex",
    },
  }));
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isDenialPopoverOpen, setIsDenialPopoverOpen] = useState(false);
  const [denialPopoverAnchor, setDenialPopoverAnchor] = useState(null);
  const [denialReasons, setDenialReasons] = useState([]);
  const [otherDenialReason, setOtherDenialReason] = useState(null);

  const handleDenialReasonChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setDenialReasons((prev) => [...prev, value]);
    } else {
      setDenialReasons((prev) => prev.filter((item) => item !== value));
    }
  };

  const confirmRejectButtonDisabled =
    denialReasons.length === 0 ||
    (denialReasons.includes("other") && (!otherDenialReason || otherDenialReason.trim() === ""));

  const updateApplicationStatus = (status) => {
    setLoading(true);
    let oldStatus = jobApplication.status;
    sh.put(`update_job_application_status/${jobApplication.id}`, {
      authenticity_token: authenticityToken,
      status: status,
      denial_reasons: denialReasons.includes("other")
        ? [...denialReasons.filter(reason => reason !== "other"), otherDenialReason]
        : denialReasons,
    })
      .then((res) => {
        replaceCurrentRecruiter(dispatch, authenticityToken, () => {});
        setJobApplication(res?.data);
        setIndexStatus(res?.data.status);

        if (oldStatus == "closed") {
          enqueueSnackbar(
            intl.formatMessage({
              id: "applicant.reopen_success",
            }),
            { variant: "success" }
          );
        } else {
          enqueueSnackbar(
            intl.formatMessage({
              id: `applicant.${res.data.status}_success`,
            }),
            { variant: "success" }
          );
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openDenialPopover = (event) => {
    setDenialPopoverAnchor(event.currentTarget);
    setIsDenialPopoverOpen(true);
  };

  const handleDenialClose = () => {
    setDenialPopoverAnchor(null);
    setIsDenialPopoverOpen(false);
  };

  const handleModalCloses = () => {
    setIsDialogOpen(false);
  };

  return (
    <Modal
      open={isDialogOpen}
      onClose={handleModalCloses}
      disableAutoFocus={true}
      style={{ display: "flex", alignItems: "center" }}
    >
      <Box className={classes.modalContainer}>
        <Box className={classes.modalBox}>
          <Grid container component={Paper}>
            {jobApplication && (
              <Grid item xs={12} md={7} className={classes.applicationAction}>
                <Box>
                  <Box
                    textAlign="center"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <img
                      src={NewApplication}
                      style={{
                        width: isDesktop ? "400px" : "180px",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    />
                    <Typography variant="body3" fontStyle="italic">
                      <FormattedMessage id="applicant.application_date" />{" "}
                      {getFormattedDate(jobApplication?.created_at, false)}
                      {!isDesktop && (
                        <>
                          <br />
                          <FormattedMessage id="applicant.application_by" />{" "}
                          {jobApplication?.candidate?.first_name}{" "}
                          {jobApplication?.candidate?.last_name}
                        </>
                      )}
                    </Typography>
                  </Box>
                  <Box style={{ marginTop: "32px", marginBottom: "48px" }}>
                    {jobApplication.status != "closed" && (
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item xs={6} md={4}>
                            <Button
                              variant="rounded"
                              color={
                                jobApplication.status == "open"
                                  ? "secondaryContained"
                                  : "secondaryOutlined"
                              }
                              fullWidth
                              disabled={
                                loading || jobApplication.status === "open"
                              }
                              onClick={() => {
                                if (jobApplication.status != "open")
                                  updateApplicationStatus("open");
                              }}
                            >
                              <FormattedMessage id="applicant.interested" />
                            </Button>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <Button
                              variant="rounded"
                              color={
                                jobApplication.status == "closed"
                                  ? "secondaryContained"
                                  : "secondaryOutlined"
                              }
                              fullWidth
                              disabled={loading}
                              onClick={openDenialPopover}
                            >
                              <FormattedMessage id="applicant.not_interested" />
                            </Button>
                            <Popover
                              id="denial-popover-id"
                              open={isDenialPopoverOpen}
                              anchorEl={denialPopoverAnchor}
                              onClose={handleDenialClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              PaperProps={{
                                style: { minWidth: "440px" },
                              }}
                            >
                              <Box style={{ padding: "16px", width: "100%" }}>
                                <Box style={{ marginBottom: "8px" }}>
                                  <Typography variant="body1bold">
                                    <FormattedMessage id="applicant.denial_reason" />
                                  </Typography>
                                </Box>
                                <FormControl style={{ width: "100%" }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={denialReasons.includes("wrong_diploma")}
                                        onChange={handleDenialReasonChange}
                                        value="wrong_diploma"
                                        color="secondary"
                                      />
                                    }
                                    label={intl.formatMessage({
                                      id: "applicant.denial_reasons.wrong_diploma",
                                    })}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={denialReasons.includes("wrong_xp")}
                                        onChange={handleDenialReasonChange}
                                        value="wrong_xp"
                                        color="secondary"
                                      />
                                    }
                                    label={intl.formatMessage({
                                      id: "applicant.denial_reasons.wrong_xp",
                                    })}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={denialReasons.includes("insufficient_languages")}
                                        onChange={handleDenialReasonChange}
                                        value="insufficient_languages"
                                        color="secondary"
                                      />
                                    }
                                    label={intl.formatMessage({
                                      id: "applicant.denial_reasons.insufficient_languages",
                                    })}
                                  />
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginBottom: "16px"
                                    }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={denialReasons.includes("other")}
                                          onChange={handleDenialReasonChange}
                                          value="other"
                                          color="secondary"
                                        />
                                      }
                                      label={intl.formatMessage({
                                        id: "applicant.denial_reasons.other",
                                      })}
                                    />
                                    {denialReasons.includes("other") && (
                                      <TextField
                                        size="small"
                                        color="secondary"
                                        value={otherDenialReason}
                                        inputProps={{ maxLength: 60 }}
                                        style={{ flexGrow: 1, marginLeft: "8px" }}
                                        onBlur={(event) =>
                                          setOtherDenialReason(event.target.value)
                                        }
                                        onChange={(event) =>
                                          setOtherDenialReason(event.target.value)
                                        }
                                      />
                                    )}
                                  </Box>
                                </FormControl>
                                <Box
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    columnGap: "16px",
                                    marginTop: "8px",
                                  }}
                                >
                                  <Button
                                    variant="rounded"
                                    color="secondaryContained"
                                    disabled={confirmRejectButtonDisabled}
                                    style={{ width: "140px" }}
                                    onClick={() => {
                                      if (jobApplication.status != "closed")
                                        updateApplicationStatus("closed");
                                    }}
                                  >
                                    <FormattedMessage id="confirm" />
                                  </Button>
                                  <Button
                                    variant="textUnderline"
                                    onClick={handleDenialClose}
                                  >
                                    <FormattedMessage id="cancel" />
                                  </Button>
                                </Box>
                              </Box>
                            </Popover>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {jobApplication.status == "closed" && (
                      <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={2}>
                          <Grid item xs={8} md={4}>
                            <Button
                              fullWidth
                              variant="rounded"
                              color={
                                jobApplication.status == "closed"
                                  ? "secondaryContained"
                                  : "secondaryOutlined"
                              }
                              disabled={loading}
                              onClick={() => {
                                if (jobApplication.status != "pending")
                                  updateApplicationStatus("pending");
                              }}
                            >
                              <FormattedMessage id="applicant.reopen" />
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                  <Box style={{ marginBottom: "16px" }}>
                    <Box style={{ marginBottom: "8px" }}>
                      <Typography variant="h4">
                        <FormattedMessage id="application.resume" />:
                      </Typography>
                    </Box>
                    <Box>
                      {jobApplication?.resume_url || candidate?.resume_url ? (
                        <Link
                          href={
                            jobApplication?.resume_url || candidate?.resume_url
                          }
                          target="_blank"
                          style={{
                            color: theme.palette.text.blue,
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                          }}
                        >
                          <FormattedMessage id="click_here_to_download" />
                          <UnarchiveOutlined
                            fontSize="large"
                            style={{
                              color: theme.palette.text.blue,
                              marginLeft: "8px",
                            }}
                          />
                        </Link>
                      ) : (
                        <Typography>No CV</Typography>
                      )}
                    </Box>
                  </Box>
                  {jobApplication.cover_letter && (
                    <Box style={{ marginBottom: "16px" }}>
                      <Box style={{ marginBottom: "8px" }}>
                        <Typography variant="h4">
                          <FormattedMessage id="application.cover_letter" />:
                        </Typography>
                      </Box>
                      <Box>{jobApplication.cover_letter}</Box>
                    </Box>
                  )}
                </Box>
              </Grid>
            )}
            {(isDesktop || !jobApplication) && (
              <Grid item xs={12} md={5} className={classes.candidateSummary}>
                <CandidateSummary candidate={candidate} standalone={false} blur={false}/>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default CandidateDialog;
