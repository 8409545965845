import React from "react";
import {
  Grid,
  Typography,
  Box,
  Paper,
  Button,
  Modal,
  Link,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useTheme } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import MetricsGrid from "bundles/common/components/Desktop/MetricsGridDesktop";
import ProfilePicture from "../ProfilePicture";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import PlaceIcon from "@mui/icons-material/Place";
import JobOfferList from "bundles/Candidate/components/JobOfferList";
import ProjectCard from "../ProjectCard";
import SpontaneousApplicationDialog from "bundles/Candidate/components/SpontaneousApplicationDialog";
import ApplicationDialog from "bundles/Candidate/components/ApplicationDialog";
import { truncate } from "bundles/common/utils/utils";
import AuthenticationModal from "bundles/Candidate/components/AuthenticationModal";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LanguageIcon from "@mui/icons-material/Language";
import PinterestIcon from "@mui/icons-material/Pinterest";
import { capitalize } from "lodash";
import { styled } from "@mui/styles";
import Badge from "@mui/material/Badge";

const useStyles = makeStyles(theme => ({
  green_paper: {
    padding: theme.spacing(2),
    margin: "auto",
    backgroundColor: theme.palette.text.light_green,
    borderRadius: "10px 10px 0px 0px",
  },
  paper: {
    padding: theme.spacing(4),
    margin: "auto",
    borderRadius: "0px 0px 10px 10px",
  },
  profile_picture: {
    borderRadius: "10px 10px 0px 0px",
    width: "100%",
  },
}));

const socialMediaIcons = {
  facebook: () => <FacebookIcon />,
  linkedin: () => <LinkedInIcon />,
  youtube: () => <YouTubeIcon />,
  instagram: () => <InstagramIcon />,
  twitter: () => <TwitterIcon />,
  pinterest: () => <PinterestIcon />,
};

const TextBlock = ({ titleId, text }) => {
  return (
    <Grid item xs={12}>
      <Typography
        variant="h6"
        gutterBottom
        style={{ textTransform: "capitalize" }}
      >
        <b>
          <FormattedMessage id={titleId} />
        </b>
      </Typography>
      <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </Typography>
    </Grid>
  );
};

const CompanyViewDesktop = ({
  company,
  jobCount,
  jobOffers,
  projects,
  currentRecruiter,
  currentCandidate,
  handleSpontaneousApplyClick,
  setIsApplying,
  isApplying,
  jobApplications,
  tabActive,
  handleChangeTab,
  sortedProjects,
  handleOfferClicked,
  selectedJob,
  handleJob,
  setHandleJob,
  setSelectedJob,
  isLoginModalOpened,
  onLoginModalCloses,
  authenticationScope,
  isSpontaneous,
  loginToAddFavorite,
  incrementWebsiteClickCount,
  authenticityToken,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      border: `2px solid ${theme.palette.primary.main}`,
      background: `${theme.palette.primary.main}`,
      color: "white",
    },
    "& .MuiButtonBase-root": {
      color: "white",
    },
  }));

  const socialLinks =
    company?.social_links?.filter(link => {
      return Object.keys(socialMediaIcons).includes(link["name"]);
    }) || [];
  const hasSocialLinks = socialLinks?.length > 0;
  const showSocialBox = hasSocialLinks || company?.domain_name;

  return (
    <>
      <Grid container>
        <Grid container>
          <Grid item xs={12} marginBottom="2rem">
            <Box className={classes.profile_picture}>
              <Box
                style={{
                  position: "relative",
                  height: "360px",
                }}
              >
                {company?.id && (
                  <img
                    src={company?.cover_picture_url}
                    srcSet={`${company?.cover_picture_url} 1x, ${company?.small_cover_picture_url} 0.5x`}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px 10px 0px 0px",
                    }}
                    alt={`Cover ${company?.name}`}
                  />
                )}
                <Box
                  style={{
                    backgroundColor: "white",
                    position: "absolute",
                    top: "calc(50% - 43px)",
                    left: "48px",
                  }}
                >
                  <ProfilePicture
                    picture={company?.logo_url}
                    picturesmall={company?.small_logo_url}
                    alt={`Logo ${company?.name}`}
                    size={120}
                    initials={company?.name?.[0]}
                  />
                </Box>
                <Box
                  style={{
                    position: "absolute",
                    top: "calc(55% - 50px)",
                    left: "200px",
                    width: "-webkit-fill-available",
                  }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="h1"
                        color={theme.palette.common.white}
                        style={{ textShadow: "1px 1px 3px rgba(0,0,0,0.3)" }}
                      >
                        <b>{truncate(company?.name, 35)}</b>
                      </Typography>
                      <Box
                        display={"flex"}
                        flexDirection="row"
                        style={{ marginLeft: "-0.3rem" }}
                      >
                        <PlaceIcon
                          style={{ color: theme.palette.common.white }}
                        />{" "}
                        <Typography color={theme.palette.common.white}>
                          {capitalize(company?.country)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box style={{ position: "relative" }}>
                <Box
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "calc(50% - 140px)",
                    left: "80%",
                  }}
                >
                  <Button
                    size="small"
                    color="primaryContained"
                    type="submit"
                    variant="rounded"
                    disabled={
                      currentRecruiter != null ||
                      currentCandidate?.blacklisted_company_ids?.includes(
                        `${company?.id}`
                      )
                    }
                    onClick={handleSpontaneousApplyClick}
                  >
                    <FormattedMessage id="spontaneous_application" />
                  </Button>
                </Box>
              </Box>

              <Modal
                hideBackdrop={false}
                open={isApplying && currentCandidate?.id}
                companyId={company?.id}
                onClose={() => setIsApplying(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ display: "flex", alignItems: "center" }}
              >
                <SpontaneousApplicationDialog
                  companyId={company?.id}
                  closeModal={() => setIsApplying(false)}
                  jobApplication={jobApplications}
                />
              </Modal>

              <Box style={{ position: "relative", width: "100%" }}>
                <Box
                  style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    borderRadius: "0px 0px 0px 0px",
                    top: "calc(22% - 10px)",
                  }}
                >
                  <Box>
                    <Box
                      width={"100%"}
                      display="flex"
                      flexDirection={"column"}
                      style={{ background: theme.palette.common.blue }}
                    >
                      <Tabs
                        value={tabActive}
                        onChange={handleChangeTab}
                        variant="fullWidth"
                        TabIndicatorProps={{
                          style: {
                            background: "white",
                            border: `2px solid ${theme.palette.primary.main}`,
                          },
                        }}
                      >
                        <Tab
                          value="about"
                          label={<FormattedMessage id="company.about" />}
                          style={{
                            color:
                              tabActive == "about"
                                ? `${theme.palette.common.white}`
                                : `${theme.palette.common.white}`,
                          }}
                        />
                        {projects &&
                          projects.filter(project => !project.is_draft).length >
                            0 && (
                            <Tab
                              value="projects"
                              label={
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: "16px",
                                  }}
                                >
                                  <FormattedMessage id="company.projects" />
                                  <StyledBadge
                                    badgeContent={
                                      projects.filter(
                                        project => !project.is_draft
                                      ).length
                                    }
                                  />
                                </Box>
                              }
                              style={{
                                color:
                                  tabActive == "projects"
                                    ? `${theme.palette.common.white}`
                                    : `${theme.palette.common.white}`,
                              }}
                            />
                          )}
                        <Tab
                          value="jobs"
                          label={
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: "16px",
                              }}
                            >
                              <FormattedMessage id="landing_recruiter.jobs" />
                              <StyledBadge badgeContent={jobCount} />
                            </Box>
                          }
                          style={{
                            color: `${theme.palette.common.white}`,
                          }}
                        ></Tab>
                      </Tabs>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          {tabActive == "about" ? (
            <Grid
              item
              xs={12}
              component={Paper}
              elevation={1}
              className={classes.paper}
            >
              <Grid container justifyContent={"flex-start"}>
                <Grid item xs={12} md={showSocialBox ? 9 : 12}>
                  <Grid container>
                    <Grid item xs={12} style={{ marginBottom: "16px" }}>
                      <Box
                        style={{
                          marginTop: "16px",
                          marginBottom: "32px",
                        }}
                      >
                        <MetricsGrid
                          titleOne="company.creation_year"
                          textOne={
                            company?.creation_year || (
                              <FormattedMessage id="undefined" />
                            )
                          }
                          titleTwo="company.employee_count"
                          textTwo={
                            company?.team_size ? (
                              <FormattedMessage
                                id={`company.company_size.options.${company.team_size}`}
                              />
                            ) : (
                              <FormattedMessage id="undefined" />
                            )
                          }
                          titleThree="company.average_age"
                          textThree={
                            company?.average_age || (
                              <FormattedMessage id="undefined" />
                            )
                          }
                          number={3}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={0} md={12} style={{ marginBottom: "16px" }}>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "32px",
                        }}
                      >
                        {/* About */}
                        <Box>
                          {company?.about && (
                            <Typography>
                              <TextBlock
                                titleId="company.about"
                                text={company.about}
                              />
                            </Typography>
                          )}
                        </Box>
                        {/* References */}
                        <Box>
                          {company?.references && (
                            <TextBlock
                              titleId="company.references"
                              text={company.references}
                            />
                          )}
                        </Box>
                        {/* Awards */}
                        <Box>
                          {company?.awards && (
                            <TextBlock
                              titleId="company.awards"
                              text={company.awards}
                            />
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                {showSocialBox && (
                  <Grid item xs={12} md={3}>
                    <Box
                      style={{
                        backgroundColor: theme.palette.secondary.main,
                        borderRadius: "8px",
                        padding: "16px",
                        color: theme.palette.common.white,
                        marginLeft: "32px",
                      }}
                    >
                      <Box
                        style={{ textAlign: "center", marginBottom: "16px" }}
                      >
                        <Typography variant="h5">
                          <FormattedMessage id="find_them" />
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "8px",
                        }}
                      >
                        <Link
                          href={`${company?.id === 8 ? "http" : "https"}://${company?.domain_name}`}
                          onClick={() => incrementWebsiteClickCount(company.id, authenticityToken)}
                          color="rgba(255, 255, 255, 0.75)"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "4px",
                            width: "max-content",
                          }}
                          target="_blank"
                        >
                          <LanguageIcon />
                          <Typography variant="body1bold">
                            <FormattedMessage id="website" />
                          </Typography>
                        </Link>
                        {socialLinks.map(link => {
                          return (
                            <Link
                              key={`link-${link["name"]}`}
                              href={link["url"]}
                              color="rgba(255, 255, 255, 0.75)"
                              target="_blank"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: "4px",
                                width: "max-content",
                              }}
                            >
                              {socialMediaIcons[link["name"]]()}
                              <Typography variant="body1bold">
                                {capitalize(link["name"])}
                              </Typography>
                            </Link>
                          );
                        })}
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ) : tabActive === "projects" ? (
            <Grid container columnSpacing={8} alignItems="center">
              {projects.length === 0 ? (
                <Grid item xs={12} sm={6} style={{ marginTop: "20px" }}>
                  <FormattedMessage id="project.none" />
                </Grid>
              ) : (
                sortedProjects
                  .filter(project => !project.is_draft)
                  .map(project => (
                    <Grid item xs={12} sm={6} key={project.id}>
                      <ProjectCard project={project} />
                    </Grid>
                  ))
              )}
            </Grid>
          ) : tabActive === "jobs" ? (
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <Box marginTop="1rem">
                  <JobOfferList
                    loading={false}
                    handleOfferClick={handleOfferClicked}
                    offers={jobOffers}
                    activeOffer={selectedJob}
                    columnPerRow={2}
                  />
                </Box>

                <Modal
                  hideBackdrop={false}
                  open={handleJob}
                  onClose={() => setHandleJob(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <ApplicationDialog
                    jobOffer={selectedJob}
                    setJobOffer={setSelectedJob}
                    setIsApplying={setHandleJob}
                    jobApplication={selectedJob?.job_applications.find(
                      application =>
                        application.candidate_id == currentCandidate?.id
                    )}
                  />
                </Modal>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
      <AuthenticationModal
        isOpen={isLoginModalOpened}
        section={"login_or_signin"}
        closeModal={onLoginModalCloses}
        singleJob={true}
        customScope={authenticationScope}
        isSpontaneous={isSpontaneous}
        addFavoriteLogin={loginToAddFavorite}
      />
    </>
  );
};

export default CompanyViewDesktop;
