import React, { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Button,
  Box,
  Link,
  Typography,
  Divider,
  TextField,
  CircularProgress,
} from "@mui/material";
import TabSwitch from "bundles/common/components/TabSwitch";
import { FormattedMessage } from "react-intl";
import PhoneInput from "react-phone-input-2";
import validator from "validator";
import { useSelector } from "react-redux";
import PasswordField from "bundles/common/components/PasswordField";

export default function StepTwo({ setRecruiterData, nextStep }) {
  const i18nLocale = useSelector((state) => state.i18nLocale);

  const initialFormValues = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    password: "",
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );
    let not_an_email = (
      <FormattedMessage id="form_validation.not_an_email"></FormattedMessage>
    );
    let not_a_strong_password = (
      <FormattedMessage id="form_validation.not_a_strong_password"></FormattedMessage>
    );
    let invalid_number = (
      <FormattedMessage id="form_validation.invalid_number"></FormattedMessage>
    );

    if ("first_name" in fieldValues) {
      temp.first_name = fieldValues.first_name ? "" : required;
    }

    if ("last_name" in fieldValues) {
      temp.last_name = fieldValues.last_name ? "" : required;
    }

    if ("phone" in fieldValues) {
      if (fieldValues.phone && fieldValues.phone.length > 24) {
        temp.phone = invalid_number;
      } else {
        temp.phone = "";
      }
    }

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
      if (fieldValues.email && !validator.isEmail(fieldValues.email))
        temp.email = not_an_email;
    }

    if ("password" in fieldValues) {
      temp.password = fieldValues.password ? "" : required;
      if (
        fieldValues.password &&
        !validator.isStrongPassword(fieldValues.password)
      )
        temp.password = not_a_strong_password;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      setRecruiterData(values);
      nextStep();
    } else {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          disableTypography
          title={
            <Typography variant="h1" align="center" style={{ fontSize: "30px" }}>
              <FormattedMessage
                id="devise.signup.build_team_html"
                values={{
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
              .
            </Typography>
          }
        ></CardHeader>
        <CardContent>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <Divider />
            </Grid>
            <Grid item>
              <TabSwitch
                textLeft={<FormattedMessage id="candidate.candidate" />}
                leftValue="candidate"
                textRight={<FormattedMessage id="recruiter.recruiter" />}
                rightValue="recruiter"
                value="recruiter"
                onClick={() => {
                  if (window)
                    window.location.href = `/${i18nLocale}/candidates/sign_up`;
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                type="text"
                size="small"
                name="first_name"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                variant="outlined"
                color="secondary"
                style={{ marginBottom: "16px" }}
                label={<FormattedMessage id="devise.signup.first_name" />}
                {...(errors["first_name"] && {
                  error: true,
                  helperText: errors["first_name"],
                })}
              ></TextField>
              <TextField
                fullWidth
                required
                type="text"
                size="small"
                variant="outlined"
                name="last_name"
                color="secondary"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                style={{ marginBottom: "16px" }}
                {...(errors["last_name"] && {
                  error: true,
                  helperText: errors["last_name"],
                })}
                label={<FormattedMessage id="devise.signup.last_name" />}
              ></TextField>
              <PhoneInput
                country={"be"}
                onlyCountries={["be", "lu"]}
                enableLongNumbers={true}
                containerStyle={{
                  marginBottom: "16px",
                }}
                inputStyle={{ height: "40px", minWidth: "100%" }}
                value={values.phone}
                color="secondary"
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: false,
                  onBlur: handleInputValue,
                  onChange: handleInputValue,
                }}
              />
              {errors["phone"] && (
                <p
                  className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required"
                  style={{ color: "#f44336" }}
                >
                  {errors["phone"]}
                </p>
              )}
              <TextField
                fullWidth
                required
                type="email"
                variant="outlined"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                color="secondary"
                name="email"
                size="small"
                style={{ marginBottom: "8px" }}
                {...(errors["email"] && {
                  error: true,
                  helperText: errors["email"],
                })}
                label={<FormattedMessage id="devise.signup.email" />}
              ></TextField>
              <PasswordField
                name="password"
                labelId="devise.signup.password"
                handleInputValue={handleInputValue}
                errors={errors}
                color="secondary"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ marginLeft: "8px", marginRight: "8px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="rounded"
                color="secondaryContained"
                size="large"
                disabled={loading}
              >
                <Box justifyContent="center">
                  {loading ? (
                    <CircularProgress size={16} color="secondary">
                      <FormattedMessage id="devise.signup.build_team" />
                    </CircularProgress>
                  ) : (
                    <FormattedMessage id="devise.signup.build_team" />
                  )}
                </Box>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" align="center">
                <FormattedMessage
                  id="devise.signup.c_v_recruiter"
                  values={{
                    link1: chunks => (
                      <Link href={`/${i18nLocale}/terms_of_use`}
                        style={{ cursor: "pointer", underline: "always" }}
                      >
                        {chunks}
                      </Link>
                    ),
                    link2: chunks => (
                      <Link href={`/${i18nLocale}/privacy_policy`}
                        style={{ cursor: "pointer", underline: "always" }}
                      >
                        {chunks}
                      </Link>
                    ),
                    link3: chunks => (
                      <Link href={`/${i18nLocale}/cookies`}
                        style={{ cursor: "pointer", underline: "always" }}
                      >
                        {chunks}
                      </Link>
                    ),
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant="body2" align="center">
                  <FormattedMessage
                    align="center"
                    id="devise.already_subscribed"
                  />{" "}
                  <Link
                    href={`/${i18nLocale}/recruiters/sign_in`}
                    color="secondary"
                    underline="always"
                  >
                    <FormattedMessage id="signin" />
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </form>
  );
}
