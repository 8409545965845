import React, { useState } from "react";
import Autocomplete from "react-google-autocomplete";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Button,
  Box,
  Link,
  Typography,
  Divider,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import sh from "bundles/common/utils/sh";
import { useSnackbar } from "notistack";
import {
  companyIndustryMap,
  getResponseMessageId,
} from "bundles/common/utils/constants";

// Event snippet for Inscription Client SH conversion page In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button.
function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof url != "undefined") {
      window.location = url;
    }
  };
  gtag("event", "conversion", {
    send_to: "AW-10868281309/HoiQCI_m_asDEN2fs74o",
    event_callback: callback,
  });
  return false;
}

export default function StepThree({
  recruiterData,
  setRecruiterData,
  setCompanyData,
  nextStep,
}) {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const railsEnv = useSelector((state) => state.railsEnv);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const initialFormValues = {
    name: "",
    job_title: "",
    company_industry: null,
    location: null,
  };

  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );

    if ("name" in fieldValues) {
      temp.name = fieldValues.name ? "" : required;
    }

    if ("job_title" in fieldValues) {
      temp.job_title = fieldValues.job_title ? "" : required;
    }

    if ("location" in fieldValues) {
      temp.location = fieldValues.location ? "" : required;
    }

    if ("company_industry" in fieldValues) {
      temp.company_industry = fieldValues.company_industry ? "" : required;
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const onPlacesSelect = (place) => {
    if (place.address_components) {
      let compPostalCode = "";
      let compLocality = "";
      let compAdministrativeAreaLevel2 = "";
      let compAdministrativeAreaLevel1 = "";

      place.address_components.forEach((c) => {
        switch (c.types[0]) {
        case "postal_code":
          compPostalCode = c.long_name;
          break;
        case "locality":
          compLocality = c.long_name;
          break;
        case "administrative_area_level_2":
          compAdministrativeAreaLevel2 = c.long_name
            ?.toLowerCase()
            ?.replace(/ /g, "_");
          break;
        case "administrative_area_level_1":
          compAdministrativeAreaLevel1 = c.long_name
            ?.toLowerCase()
            ?.replace(/ /g, "_");
          break;
        }
      });

      const country = place.address_components
        .find((c) => c.types.includes("country"))
        .long_name?.toLowerCase();

      if (
        country == "belgium" &&
        compAdministrativeAreaLevel2 == "luxembourg"
      ) {
        compAdministrativeAreaLevel2 = "be_luxembourg";
      }

      let newValues = {
        ...values,
        location: place.formatted_address,
        zipcode: compPostalCode,
        city: compLocality,
        region:
          compAdministrativeAreaLevel2 != ""
            ? compAdministrativeAreaLevel2
            : compAdministrativeAreaLevel1,
        country: country,
      };

      setValues(newValues);
    }
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    if (validate(values)) {
      setRecruiterData({ ...recruiterData, job_title: values["job_title"] });
      setCompanyData({
        name: values["name"],
        company_industry: values["company_industry"],
        zipcode: values["zipcode"],
        city: values["city"],
        region: values["region"],
        country: values["country"],
      });
      createCompany();
    } else {
      setLoading(false);
    }
  };

  const createCompany = () => {
    if (validate(values)) {
      sh.post(`${i18nLocale}/recruiters`, {
        recruiter: { ...recruiterData, job_title: values["job_title"] },
        company: {
          name: values["name"],
          company_industry: values["company_industry"],
          zipcode: values["zipcode"],
          city: values["city"],
          region: values["region"],
          country: values["country"],
        },
      })
        .then((res) => {
          if (typeof fbq !== "undefined")
            fbq("trackCustom", "Inscription Recruteur SH");
          if (window && typeof window.lintrk !== "undefined")
            window.lintrk("track", { conversion_id: 8587650 });
          if (railsEnv === "production" && typeof gtag !== "undefined")
            gtag_report_conversion();

          setCompanyData({ name: values["name"], id: res?.data?.company_id });
          nextStep();
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.data?.message) {
            enqueueSnackbar(
              getResponseMessageId(err?.response?.data?.message),
              { variant: "error" }
            );
          }
          console.error("Error", err);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card>
        <CardHeader
          disableTypography
          title={
            <Typography variant="h2" align="center">
              <FormattedMessage
                id="devise.signup.tell_us_more_recruiter_html"
                values={{
                  name: recruiterData.first_name,
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
            </Typography>
          }
        ></CardHeader>
        <CardContent>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                margin="normal"
                name="name"
                size="small"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                variant="outlined"
                label={<FormattedMessage id="devise.signup.company_name" />}
                color="secondary"
                {...(errors["name"] && {
                  error: true,
                  helperText: errors["name"],
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                type="text"
                margin="normal"
                size="small"
                variant="outlined"
                onBlur={handleInputValue}
                onChange={handleInputValue}
                name="job_title"
                {...(errors["job_title"] && {
                  error: true,
                  helperText: errors["job_title"],
                })}
                label={<FormattedMessage id="devise.signup.job_title" />}
                color="secondary"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                margin="normal"
                size="small"
                color="secondary"
                {...(errors["company_industry"] && {
                  error: true,
                  helperText: errors["company_industry"],
                })}
              >
                <InputLabel id="field-label">
                  <FormattedMessage id="devise.signup.company_industry" />
                </InputLabel>
                <Select
                  id="type"
                  label={`${intl.formatMessage({
                    id: "devise.signup.company_industry",
                  })}`}
                  name="company_industry"
                  value={values.company_industry}
                  onChange={handleInputValue}
                >
                  {companyIndustryMap.map((value) => (
                    <MenuItem value={value} key={value}>
                      <FormattedMessage
                        id={"company.company_industry." + value}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} style={{ marginTop: "16px" }}>
              <TextField
                fullWidth
                color="secondary"
                name="location"
                size="small"
                variant="outlined"
                InputProps={{
                  inputComponent: ({ ...props }) => (
                    <Autocomplete
                      /* eslint-disable no-undef */
                      apiKey={process.env.GOOGLE_AUTOCOMPLETE_API_KEY}
                      language="en"
                      {...props}
                      onPlaceSelected={onPlacesSelect}
                      options={{
                        types: ["(regions)"],
                        componentRestrictions: {
                          country: ["be", "lu"],
                        },
                      }}
                      defaultValue={values.location}
                      placeholder={intl.formatMessage({ id: "devise.signup.location" })}
                    />
                  ),
                }}
                {...(errors["location"] && {
                  error: true,
                  helperText: errors["location"],
                })}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions style={{ marginLeft: "8px", marginRight: "8px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
                fullWidth
                type="submit"
                variant="rounded"
                color="secondaryContained"
                size="large"
                disabled={loading}
              >
                <Box justifyContent="center">
                  {loading && (
                    <CircularProgress size={16} color="secondary">
                      <FormattedMessage id="next" />
                    </CircularProgress>
                  )}
                  {!loading && (
                    <FormattedMessage id="devise.signup.build_team" />
                  )}
                </Box>
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Box mb={2}>
                <Typography variant="body2" align="center">
                  <FormattedMessage
                    align="center"
                    id="devise.already_subscribed"
                  />{" "}
                  <Link
                    href={`/${i18nLocale}/recruiters/sign_in`}
                    color="secondary"
                    underline="always"
                  >
                    <FormattedMessage id="signin" />
                  </Link>
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </form>
  );
}
