import { Box, Grid, Typography, Container, Link } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

const SiteMap = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  return (
    <Container maxWidth="lg">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box my={2}>
            <Typography variant="h1" align="center">
              <b>
                <FormattedMessage id="footer.site_map.site_map" />
              </b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {/* SQUAREHUB LANDING PAGES */}
            <Grid item xs={12} md={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <b>
                      <FormattedMessage id="menu.home" />
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li>
                      <Link href={`/${i18nLocale}`} color="textPrimary">
                        <FormattedMessage id="candidate.candidate" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={`/${i18nLocale}/recruiter`}
                        color="textPrimary"
                      >
                        <FormattedMessage id="recruiter.recruiter" />
                      </Link>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>
            {/* CANDIDATES */}
            <Grid item xs={12} md={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <b>
                      <FormattedMessage id="menu.candidates" />
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li>
                      <Link
                        href={`/${i18nLocale}/candidates/sign_in`}
                        color="textPrimary"
                      >
                        <FormattedMessage id="homepage" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={`/${i18nLocale}/candidate/settings`}
                        color="textPrimary"
                      >
                        <FormattedMessage id="profile.my_profile" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={`/${i18nLocale}/candidate/job_offers`}
                        color="textPrimary"
                      >
                        <FormattedMessage id="job_search.my_jobs" />
                      </Link>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>

            {/* RECRUTEURS */}
            <Grid item xs={12} md={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <b>
                      <FormattedMessage id="menu.recruiters" />
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li>
                      <Link
                        href={`/${i18nLocale}/recruiter/sign_in`}
                        color="textPrimary"
                      >
                        <FormattedMessage id="homepage" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={`/${i18nLocale}/recruiter/job_offers`}
                        color="textPrimary"
                      >
                        <FormattedMessage id="menu.jobs" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={`/${i18nLocale}/recruiter/job_offer_creation`}
                        color="textPrimary"
                      >
                        <FormattedMessage id="menu.post_job_offer" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={`/${i18nLocale}/recruiter/find_candidate`}
                        color="textPrimary"
                      >
                        <FormattedMessage id="candidate_search.find_candidate" />
                      </Link>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>

            {/* LEGAL */}
            <Grid item xs={12} md={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <b>
                      <FormattedMessage id="menu.legal" />
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <ul>
                    <li>
                      <Link
                        href={`/${i18nLocale}/terms_of_use`}
                        color="textPrimary"
                      >
                        <FormattedMessage id="footer.terms_of_use.terms_of_use" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={`/${i18nLocale}/privacy_policy`}
                        color="textPrimary"
                      >
                        <FormattedMessage id="footer.privacy_policy.privacy_policy" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        href={`/${i18nLocale}/site_map`}
                        color="textPrimary"
                      >
                        <FormattedMessage id="footer.site_map.site_map" />
                      </Link>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </Grid>

            {/* HELP */}
            {/*
            <Grid item xs={12} md={3}>
              <Grid container>
                  <Grid item xs={12}>
                      <Typography variant="h6">
                          <b><FormattedMessage id="menu.legal" /></b>
                      </Typography>
                  </Grid>
                  <Grid item xs={12}>
                      <ul>
                          <li>
                              <Link href="/contact_us" color="textPrimary">
                                  <FormattedMessage id="/contact_us" />
                              </Link>
                          </li>
                      </ul>
                  </Grid>
              </Grid>
            </Grid>
            */}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box my={4} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default SiteMap;
