import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  TextField,
  useMediaQuery,
} from "@mui/material";
import sh from "bundles/common/utils/sh";
import { FormattedMessage, useIntl } from "react-intl";
import validator from "validator";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/styles";
import Hello from "icons/hello.svg";

const NewContactForm = ({ email = "", candidateForm = false }) => {
  const currentCandidate = useSelector((state) => state.current_candidate);
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const initialFormValues = {
    email: email,
    phone: "",
    content: "",
  };
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const validate = (fieldValues = values) => {
    fieldValues.email = email || fieldValues.email;
    let temp = { ...errors };
    let required = (
      <FormattedMessage id="form_validation.required"></FormattedMessage>
    );
    let not_an_email = (
      <FormattedMessage id="form_validation.not_an_email"></FormattedMessage>
    );

    if ("email" in fieldValues) {
      temp.email = fieldValues.email ? "" : required;
      if (fieldValues.email) {
        if (!validator.isEmail(fieldValues.email)) temp.email = not_an_email;
        else {
          temp.email = "";
        }
      }
    }

    if ("phone" in fieldValues) {
      temp.phone = fieldValues.phone ? "" : required;
    }

    if ("content" in fieldValues) {
      temp.content = fieldValues.content ? "" : required;
    }
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
    validate({ [name]: value });
  };

  const handleSubmit = (e) => {
    setLoading(true);
    setIsSubmitted(true);
    e.preventDefault();
    if (validate(values)) {
      let params = {
        email: values.email,
        phone: values.phone,
        content: values.content,
        preferred_locale:
          currentCandidate?.preferred_locale ||
          currentRecruiter?.preferred_locale,
        locale: i18nLocale,
      };
      sh.post("contact_us", { data: params })
        .then((res) => {
          console.log(res);
          setIsSuccess(true);
        })
        .catch(() => {
          enqueueSnackbar(
            intl.formatMessage({
              id: "flash.unknown",
            }),
            { variant: "error" }
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };
  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        justifyContent="center"
        elevation={1}
        style={{
          width: "100%",
          borderRadius: "8px",
          paddingLeft: isDesktop ? "32px" : "0px",
        }}
      >
        {!isSuccess ? (
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              flexDirection="column"
            >
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{ marginBottom: "8px", color: "white" }}
                >
                  <FormattedMessage
                    id="landing_recruiter.email_description"
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </Typography>
                <TextField
                  required
                  name="email"
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  color={"secondary"}
                  placeholder={intl.formatMessage({
                    id: candidateForm ? "landing_candidate.contact_form.email_address_example" : "landing_recruiter.contact_form.email_address",
                  })}
                  variant="outlined"
                  component={Paper}
                  fullWidth
                  error={isSubmitted && Boolean(errors.email)}
                  helperText={isSubmitted ? errors.email : ""}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{ marginBottom: "8px", color: "white" }}
                >
                  <FormattedMessage
                    id="landing_recruiter.phone_description"
                    values={{
                      b: (chunks) => <b>{chunks}</b>,
                    }}
                  />
                </Typography>
                <TextField
                  required
                  name="phone"
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  component={Paper}
                  color={"secondary"}
                  placeholder={intl.formatMessage({
                    id: "landing_recruiter.contact_form.phone",
                  })}
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  style={{ marginBottom: "8px", color: "white" }}
                >
                  {candidateForm
                    ? <FormattedMessage
                      id="landing_candidate.contact_form.message_description"
                      values={{
                        b: (chunks) => <b>{chunks}</b>,
                      }}
                    />
                    : <FormattedMessage
                      id="landing_recruiter.message_description"
                      values={{
                        b: (chunks) => <b>{chunks}</b>,
                      }}
                    />
                  }
                </Typography>
                <TextField
                  required
                  multiline
                  rows={4}
                  onBlur={handleInputValue}
                  onChange={handleInputValue}
                  component={Paper}
                  color={"secondary"}
                  name="content"
                  placeholder={intl.formatMessage({
                    id: candidateForm ? "landing_candidate.contact_form.message_placeholder" : "landing_recruiter.contact_form.your_question",
                  })}
                  variant="outlined"
                  fullWidth
                  inputProps={{
                    style: { height: "100px", overflowY: "auto" },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box my={2} textAlign="left">
                  <Button
                    variant="rounded"
                    color={"whiteContained"}
                    type="submit"
                    disabled={loading || isSuccess}
                    style={{
                      minWidth: isDesktop ? "240px" : "100%",
                      height: "55px",
                    }}
                  >
                    <FormattedMessage id="send" />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginBottom: "32px",
            }}
          >
            <Typography variant="h4" align="center" style={{ color: "white" }}>
              <FormattedMessage id="landing_recruiter.contact_form.contact_success" />
            </Typography>
            <Box
              component="img"
              alt="message_sent"
              width="75%"
              height="auto"
              src={Hello}
              style={{ marginTop: "50px" }}
            />
          </Grid>
        )}
      </Grid>
    </form>
  );
};

export default NewContactForm;
