import React from "react";
import { useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import translations from "app/libs/i18n/translations.json";
// import configureStore from '../store/helloWorldStore';
// import HelloWorldContainer from '../containers/HelloWorldContainer';
import Candidate from "../components/Candidate";
import CompanyPage from "../components/CompanyPage";
import CompanyIndex from "../components/CompanyIndex";
import JobOffers from "../components/JobOffers";
import PublicJobOffer from "../components/PublicJobOffer";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
// See documentation for https://github.com/reactjs/react-redux.
// This is how you get props from the Rails view into the redux store.
// This code here binds your smart component to the redux store.
import MainAppBar from "bundles/Layouts/MainAppBar";
import Footer from "bundles/Layouts/Footer";
import Faq from "bundles/common/components/Faq";
import ContactUs from "bundles/common/components/ContactUs";
// import JoinUs from "bundles/common/components/JoinUs";
import PrivacyPolicy from "bundles/common/components/PrivacyPolicy";
import Cookies from "bundles/common/components/Cookies";
import GeneralSalesConditions from "bundles/common/components/GeneralSalesConditions";
import SiteMap from "bundles/common/components/SiteMap";
import TermsOfUse from "bundles/common/components/TermsOfUse";
import ScrollToTop from "bundles/common/utils/scrollToTop";
import Godview from "bundles/common/components/Godview";
import Settings from "../components/Settings";
import Blog from "bundles/common/components/Blog";
import BlogView from "bundles/common/components/BlogView";
import ProjectPage from "bundles/common/components/ProjectPage";
import Unsubscribe from "bundles/common/components/Unsubscribe";

const CandidateContainer = () => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const locale = i18nLocale;
  const candidate = useSelector((state) => state.current_candidate) || {};
  const godviewSessionMessage = candidate?.godview;
  const messages = translations[locale];
  const theme = useTheme();

  if (
    godviewSessionMessage == null &&
    (!candidate?.id || !candidate?.email.includes("squarehub")) &&
    typeof smartlook !== "undefined"
  ) {
    smartlook("identify", `candidate-${candidate?.id}`, {
      name: `${candidate?.first_name} ${candidate?.last_name}`,
      email: candidate?.email,
      region: candidate?.region,
      "zip code": candidate?.zipcode,
    });
  }

  return (
    <IntlProvider locale={locale} key={locale} messages={messages}>
      <BrowserRouter>
        <Box
          id="recruiter-main-box"
          display="flex"
          flexDirection="column"
          style={{ minHeight: "100vh" }}
        >
          {godviewSessionMessage && <Godview message={godviewSessionMessage} />}
          <MainAppBar />
          <Box
            className={theme.background}
            style={{ height: "100%", flexGrow: 10 }}
          >
            <ScrollToTop />
            <Routes>
              <Route
                path={`/${i18nLocale}/candidate/settings`}
                element={<Settings />}
              />
              <Route
                path={`/${i18nLocale}/candidate/FAQ`}
                element={<Faq type="candidate" userEmail={candidate.email} />}
              />
              <Route
                path={`/${i18nLocale}/jobs/:job_title/:job_offer_id`}
                element={<PublicJobOffer />}
              />
              <Route
                path={`/${i18nLocale}/candidate/public_job_offers/:job_offer_id`}
                element={<PublicJobOffer />}
              />
              <Route
                path={`/${i18nLocale}/candidate/job_offers`}
                element={<JobOffers />}
              />
              <Route
                path={`/${i18nLocale}/companies/:company_name/:id`}
                element={<CompanyPage />}
              />
              <Route
                path={`/${i18nLocale}/companies/:company_name/project/:project_name/:id`}
                element={<ProjectPage />}
              />
              <Route
                path={`/${i18nLocale}/companies/:company_name/:company_id/project/:project_name/:id`}
                element={<ProjectPage />}
              />
              <Route
                path={`/${i18nLocale}/companies/:company_name/:company_id/project/:id`}
                element={<ProjectPage />}
              />
              <Route
                path={`/${i18nLocale}/candidate/company/:id`}
                element={<CompanyPage />}
              />
              <Route
                path={`/${i18nLocale}/candidate/company/project/:id`}
                element={<ProjectPage />}
              />
              <Route
                path={`/${i18nLocale}/recruiters/posts/:blog_title/:id`}
                element={<BlogView type="candidate" />}
              />
              <Route
                path={`/${i18nLocale}/recruiter/posts/:blog_title/:id`}
                element={<BlogView type="candidate" />}
              />
              <Route
                path={`/${i18nLocale}/candidates/posts/:blog_title/:id`}
                element={<BlogView type="candidate" />}
              />
              <Route
                path={`/${i18nLocale}/candidate/posts/:blog_title/:id`}
                element={<BlogView type="candidate" />}
              />
              <Route
                path={`/${i18nLocale}/candidates/posts/:id`}
                element={<BlogView type="candidate" />}
              />
              <Route
                path={`/${i18nLocale}/candidate/posts/:id`}
                element={<BlogView type="candidate" />}
              />
              <Route
                path={`/${i18nLocale}/candidates/posts`}
                element={<Blog type="candidate" />}
              />
              <Route
                path={`/${i18nLocale}/candidate/posts`}
                element={<Blog type="candidate" />}
              />
              <Route
                path={`/${i18nLocale}/candidate/contact_us`}
                element={<ContactUs />}
              />
              {/* <Route
                path={`/${i18nLocale}/candidate/join_us`}
                element={<JoinUs />}
              /> */}
              <Route
                path={`/${i18nLocale}/candidate/privacy_policy`}
                element={<PrivacyPolicy />}
              />
              <Route
                path={`/${i18nLocale}/candidate/cookies`}
                element={<Cookies />}
              />
              <Route
                path={`/${i18nLocale}/candidate/general_sales_conditions`}
                element={<GeneralSalesConditions />}
              />
              <Route
                path={`/${i18nLocale}/candidate/site_map`}
                element={<SiteMap />}
              />
              <Route
                path={`/${i18nLocale}/candidate/terms_of_use`}
                element={<TermsOfUse />}
              />
              <Route
                path={`/${i18nLocale}/candidate`}
                element={<Candidate />}
              />
              <Route
                path={`/${i18nLocale}/companies`}
                element={<CompanyIndex />}
              />
              <Route
                path={`/${i18nLocale}/candidate/companies`}
                element={<CompanyIndex />}
              />
              <Route
                path={`/${i18nLocale}/candidates/unsubscribes`}
                element={<Unsubscribe />}
              />
              <Route
                path={`/${i18nLocale}/`}
                element={<Navigate to={`/${i18nLocale}/candidate`} />}
              />
            </Routes>
          </Box>
        </Box>
      </BrowserRouter>
      <Footer />
    </IntlProvider>
  );
};

export default CandidateContainer;
