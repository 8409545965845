import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, Modal, Badge } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import {
  companyIndustryMap,
  availabilities,
  experience,
} from "bundles/common/utils/constants";
import { makeStyles } from "@mui/styles";
import translations from "app/libs/i18n/translations.json";
import SmartChurchBar from "bundles/common/components/SmartChurchBar";
import FilteringModal from "./FilteringModal";
import { be_regions, lu_regions } from "bundles/common/utils/constants";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "920px",
  },
  filterButtonBox: {
    marginTop: "16px",
    display: "flex",
    columnGap: "8px",
  },
  filterButton: {
    padding: "4px 8px",
    border: "1px solid #F5F5F5",
    borderRadius: "24px",
    backgroundColor: "inherit",
    color: "#FFFFFF",

    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  badge: {
    "& .MuiBadge-badge": {
      marginRight: "4px",
    },
  },
}));

const AdvancedFiltering = ({
  onlyFavorites,
  setSelectedAvailabilities,
  setSelectedSectors,
  setSelectedExperiences,
  setSelectedHardSkills,
  setSelectedSoftSkills,
  setSelectedLanguages,
  setSelectedRegions,
  setOnlyFavorites,
  clearAdvancedFilters,
  queryText,
  setQueryText,
  fetchRecords,
}) => {
  const i18nLocale = useSelector((state) => state.i18nLocale);
  const intl = useIntl();

  const classes = useStyles();
  const hardSkillsList = Object.entries(translations[i18nLocale])
    .filter((elem) => elem[0].includes("skills_list.hard"))
    .map((elem) => elem[0].split(".").slice(-1))
    .flat();
  const softSkillsList = Object.entries(translations[i18nLocale])
    .filter((elem) => elem[0].includes("skills_list.soft"))
    .map((elem) => elem[0].split(".").slice(-1))
    .flat();
  const languagesSkillsList = Object.entries(translations[i18nLocale])
    .filter((elem) => elem[0].includes("skills_list.lang"))
    .map((elem) => elem[0].split(".").slice(-1))
    .flat();

  const [filteringModalOpened, setFilteringModalOpened] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);

  const query = useQuery();
  const isInitialMount = useRef(true);
  const [preSetFilters, setPreSetFilters] = useState({});

  const sections = useMemo(() => {
    return [
      {
        code: "sector",
        label: "candidate.desired_sector",
        values: companyIndustryMap.map((industry) => {
          return {
            code: industry,
            label: `company.company_industry.${industry}`,
          };
        }),
        display: "box",
      },
      {
        code: "availability",
        label: "candidate.availability",
        values: availabilities.map((availability) => {
          return {
            code: availability,
            label: `candidate.${availability}`,
          };
        }),
        display: "box",
      },
      {
        code: "experience",
        label: "candidate.experience",
        values: experience.map((xp) => {
          return {
            code: xp.value,
            label: `${xp.label} ${intl.formatMessage({ id: "candidate.years" })}`,
          };
        }),
        display: "box",
      },
      {
        code: "hard_skills",
        label: "candidate.profile.skills.hard",
        values: hardSkillsList
          .map((skill) => {
            return {
              code: skill,
              label: translations[i18nLocale][`skills_list.hard.${skill}`],
            };
          })
          .sort((a, b) => {
            let aLabel = a.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            let bLabel = b.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            if (aLabel < bLabel) {
              return -1;
            } else if (aLabel > bLabel) {
              return 1;
            }
            return 0;
          }),
        display: "skill_select",
      },
      {
        code: "soft_skills",
        label: "candidate.profile.skills.soft",
        values: softSkillsList
          .map((skill) => {
            return {
              code: skill,
              label: translations[i18nLocale][`skills_list.soft.${skill}`],
            };
          })
          .sort((a, b) => {
            let aLabel = a.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            let bLabel = b.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            if (aLabel < bLabel) {
              return -1;
            } else if (aLabel > bLabel) {
              return 1;
            }
            return 0;
          }),
        display: "skill_select",
      },
      {
        code: "lang_skills",
        label: "candidate.profile.skills.lang",
        values: languagesSkillsList
          .map((skill) => {
            return {
              code: skill,
              label: translations[i18nLocale][`skills_list.lang.${skill}`],
            };
          })
          .sort((a, b) => {
            let aLabel = a.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            let bLabel = b.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
            if (aLabel < bLabel) {
              return -1;
            } else if (aLabel > bLabel) {
              return 1;
            }
            return 0;
          }),
        display: "skill_select",
      },
      {
        code: "region",
        label: "regions.location",
        display: "select_region",
        values: be_regions.concat(lu_regions).map((region) => {
          return {
            code: region,
            label: intl.formatMessage({ id: `regions.${region}` }),
          };
        }),
      },
    ];
  }, [intl]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      // Parsing filters from URL
      const sectorFromUrl = query.get("selectedSector");
      const regionFromUrl = query.get("selectedRegion");
      const experienceFromUrl = query.get("selectedExperience");

      let newFilters = {};
      if (sectorFromUrl) newFilters["sector"] = [sectorFromUrl];
      if (regionFromUrl) newFilters["region"] = regionFromUrl.split(",");
      if (experienceFromUrl) {
        const experienceSection = sections.find(section => section.code === "experience");
        const experienceObject = findExperienceCodeLabel(experienceFromUrl, experienceSection.values, intl);
        if (experienceObject) {
          newFilters["experience"] = [experienceObject.code];
        }
      }

      setSelectedFilters(prevFilters => ({ ...prevFilters, ...newFilters }));
    }
  }, [query, sections, intl]);

  function findExperienceCodeLabel(experienceString, experienceValues, intl) {
    let experienceObject = null;

    if (experienceString === "10+") {
      experienceObject = experienceValues.find(exp => exp.code.length === 10 && exp.code[0] === 11);
    } else {
      const years = experienceString.split(",").map(Number);
      experienceObject = experienceValues.find(exp =>
        exp.code.length === years.length &&
        exp.code.every((val, index) => val === years[index])
      );
    }

    if (experienceObject) {
      return {
        ...experienceObject,
        label: `${experienceObject.label} ${intl.formatMessage({ id: "candidate.years" })}`
      };
    }

    return null;
  }

  const [selectedFilters, setSelectedFilters] = useState(() => {
    let json = {};
    sections.forEach((section) => {
      json[section.code] = [];
    });
    return json;
  });

  const hasActiveFilters = Object.values(selectedFilters || {}).flat().length > 0 ||
                          Object.keys(preSetFilters).some(key => preSetFilters[key]);

  const openFilteringModal = (section) => {
    setSelectedSection(section);
    setFilteringModalOpened(true);
  };

  const clearAll = () => {
    let json = {};
    sections.forEach((section) => {
      json[section.code] = [];
    });
    setSelectedFilters(json);
    clearAdvancedFilters();
  };

  const handleFilteringModalSearch = () => {
    Object.entries(selectedFilters || {}).forEach(([section, filters]) => {
      let newFiltersArray = [];

      filters.forEach((filter) => {
        if (Array.isArray(filter)) {
          newFiltersArray = [...newFiltersArray, ...filter];
        } else {
          newFiltersArray.push(filter);
        }
      });
      switch (section) {
      case "sector":
        setSelectedSectors(newFiltersArray);
        break;
      case "availability":
        setSelectedAvailabilities(newFiltersArray);
        break;
      case "experience":
        setSelectedExperiences(newFiltersArray);
        break;
      case "hard_skills":
        setSelectedHardSkills(newFiltersArray);
        break;
      case "soft_skills":
        setSelectedSoftSkills(newFiltersArray);
        break;
      case "lang_skills":
        setSelectedLanguages(newFiltersArray);
        break;
      case "region":
        setSelectedRegions(newFiltersArray);
        break;
      }
    });
  };

  useEffect(() => {
    handleFilteringModalSearch();
  }, [Object.values(selectedFilters || {}).flat()?.length]);

  return (
    <>
      <Box className={classes.container} id="sh-advanced-filtering">
        <Box>
          <SmartChurchBar
            queryText={queryText}
            setQueryText={setQueryText}
            fetchRecords={fetchRecords}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            sections={sections}
            onlyFavorites={onlyFavorites}
            setOnlyFavorites={setOnlyFavorites}
            placeholder="landing_recruiter.searched_candidates"
          />
        </Box>
        <Box className={classes.filterButtonBox}>
          {sections.map((section) => (
            <Badge
              key={`filter-button-${section.code}`}
              color="primary"
              badgeContent={selectedFilters[section.code]?.length || 0}
              className={classes.badge}
            >
              <Button
                className={classes.filterButton}
                onClick={() => openFilteringModal(section.code)}
              >
                <FormattedMessage id={section.label} />
              </Button>
            </Badge>
          ))}
          {hasActiveFilters && (
            <Button
              variant="textUnderline"
              onClick={clearAll}
              style={{ color: "white", padding: "0px" }}
            >
              <FormattedMessage id="job_search.clear_filters" />
            </Button>
          )}
        </Box>
      </Box>
      <Modal
        open={filteringModalOpened}
        onClose={() => setFilteringModalOpened(false)}
        aria-labelledby="modal-filtering-title"
        aria-describedby="modal-filtering-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FilteringModal
          sections={sections}
          selectedSection={selectedSection}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          onlyFavorites={onlyFavorites}
          setOnlyFavorites={setOnlyFavorites}
          closeModal={() => setFilteringModalOpened(false)}
        />
      </Modal>
    </>
  );
};

export default AdvancedFiltering;
