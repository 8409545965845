import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import WorkOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import KeyIcon from "icons/Key.svg";
import ChatIcon from "icons/Bubble.svg";
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import Hand from "icons/hand.svg";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import Dollar from "icons/dollar_new.svg";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import Magnifying from "icons/magnifying_glass.svg";

export const mobileUnauthMenus = [
  {
    titleId: "menu.pricing",
    target: "recruiter/pricing",
    icon: () => <img src={KeyIcon} alt="Login Icon" style={{ width: "15px", height: "15px", marginLeft: "2px" }}/>,
  },
  {
    titleId: "nav.contact",
    target: "contact_us",
    icon: () => <img src={ChatIcon} alt="Chat Icon" style={{ width: "15px", height: "15px", marginLeft: "2px" }}/>,
  },
  {
    titleId: "nav.faq",
    target: "recruiters/faq",
    icon: () => <HelpOutlineIcon style={{ width: "18px", height: "18px" }} />,
  },
  {
    titleId: "nav.recruiter.go_candidate",
    logout: true,
    icon: () => <SwapHorizIcon style={{ width: "18px", height: "18px" }} />,
  },
];

export const mobileAuthenticatedSubmenus = [
  {
    code: "recruiter",
    titleId: "nav.recruiter.recruit",
    links: [
      {
        titleId: "nav.recruiter.post_job",
        target: "recruiter/job_offer_creation",
        icon: () => (
          <AddCircleOutlineIcon style={{ width: "18px", height: "18px" }} />
        ),
      },
      {
        titleId: "nav.recruiter.my_offers",
        target: "recruiter/job_offers",
        icon: () => <WorkOutlinedIcon style={{ width: "18px", height: "18px" }} />,
      },
      {
        titleId: "nav.recruiter.my_applications",
        target: "recruiter/my_candidates",
        icon: () => <PeopleOutlineIcon style={{ width: "18px", height: "18px" }} />,
      },
      {
        titleId: "nav.recruiter.find_candidate",
        target: "recruiter/find_candidate",
        icon: () => <img src={Magnifying} alt="magnifying glass" style={{ width: "15px", height: "15px", marginLeft: "2px" }}/>,
      },
      {
        titleId: "nav.recruiter.favorite_candidates",
        target: "recruiter/find_candidate",
        search: "?onlyFavorites=true",
        icon: () => <FavoriteBorderIcon style={{ width: "18px", height: "18px" }} />,
      },
    ],
    isOpen: false,
  },
  {
    code: "billing",
    titleId: "nav.recruiter.billing",
    links: [
      {
        titleId: "nav.recruiter.order_history",
        target: "recruiter/order_history",
        icon: () => <img src={Dollar} alt="Dollar Icon" style={{ width: "15px", height: "15px", marginLeft: "2px" }}/>,
      },
      {
        titleId: "nav.recruiter.pricing",
        target: "recruiter/pricing",
        icon: () => <img src={Hand} alt="Hand Icon" style={{ width: "15px", height: "15px", marginLeft: "2px" }}/>,
      },
    ],
    isOpen: false,
  },
  {
    code: "help",
    titleId: "nav.help",
    links: [
      {
        titleId: "nav.faq",
        target: "recruiter/faq",
        icon: () => (
          <HelpOutlineIcon style={{ width: "18px", height: "18px" }} />
        ),
      },
      {
        titleId: "nav.contact",
        target: "recruiter/contact_us",
        icon: () => <img src={ChatIcon} alt="Login Icon" style={{ width: "15px", height: "15px", marginLeft: "2px" }}/>,
      },
      {
        titleId: "nav.recruiter.go_to_candidate",
        logout: true,
        icon: () => <SwapHorizIcon style={{ width: "18px", height: "18px" }} />,
      },
    ],
    isOpen: false,
  },
];

export const desktopAuthenticatedSubmenus = [
  {
    titleId: "nav.recruiter.billing",
    links: [
      {
        titleId: "nav.recruiter.order_history",
        target: "recruiter/order_history",
        icon: () => <MonetizationOnOutlinedIcon style={{ width: "18px", height: "18px" }} />,
      },
      {
        titleId: "nav.recruiter.pricing",
        target: "recruiter/pricing",
        icon: () => <AccountBalanceOutlinedIcon style={{ width: "18px", height: "18px" }} />,
      },
    ],
  },
  {
    code: "help",
    titleId: "nav.help",
    links: [
      {
        titleId: "nav.faq",
        target: "recruiter/faq",
        icon: () => (
          <HelpOutlineIcon style={{ width: "18px", height: "18px" }} />
        ),
      },
      {
        titleId: "nav.contact",
        target: "recruiter/contact_us",
        icon: () => <SmsOutlinedIcon style={{ width: "18px", height: "18px" }} />,
      },
      {
        titleId: "nav.recruiter.go_to_candidate",
        logout: true,
        icon: () => <SwapHorizIcon style={{ width: "18px", height: "18px" }} />,
      },
    ],
    isOpen: false,
  },
];
