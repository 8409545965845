import React from "react";
import { useSelector } from "react-redux";
import { Box, Typography, Grid, Link } from "@mui/material";
import Quotes from "icons/quotes_icon.svg";
import { FormattedMessage } from "react-intl";
import { useMediaQuery, useTheme } from "@mui/material";

const CarouselItem = ({ carouselItem }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const i18nLocale = useSelector((state) => state.i18nLocale);

  return (
    <Grid
      container
      spacing={4}
      alignItems="center"
      direction="row"
      justifyContent="center"
      style={{ height: isDesktop ? "500px" : "400px" }}
    >
      <Grid item xs={12} md={6} style={{ paddingTop: 0 }}>
        <Box
          textAlign="center"
          style={{ width: isDesktop ? "380px" : "100%", marginLeft: "auto" }}
        >
          <img
            src={Quotes}
            alt="Quotes"
            style={{ width: "50px", height: "50px" }}
          />
          <Typography
            variant="h5"
            style={{
              fontStyle: "italic",
              marginTop: "30px",
              marginBottom: "30px",
              fontSize: isDesktop ? "18px" : "12px",
              padding: isDesktop ? "unset" : "20px",
            }}
          >
            <FormattedMessage
              id={carouselItem.text}
              values={{
                b: (chunks) => <b>{chunks}</b>,
              }}
            />
          </Typography>
          <Typography
            variant="h5"
            style={{ fontWeight: "bold", marginTop: "20px", fontSize: "18px" }}
          >
            <FormattedMessage id={carouselItem.name} />
          </Typography>
          <Typography
            variant="h7"
            style={{ fontSize: isDesktop ? "14px" : "12px" }}
          >
            <FormattedMessage
              id={carouselItem.position}
              values={{
                a: (chunks) => (
                  <Link
                    variant="textUnderline"
                    target="_blank"
                    href={carouselItem.companyUrl}
                  >
                    {chunks}
                  </Link>
                ),
              }}
            />
          </Typography>
        </Box>
      </Grid>
      {isDesktop && (
        <Grid
          item
          xs={12}
          md={6}
          style={{ paddingTop: 0, paddingLeft: "100px" }}
        >
          <img
            src={carouselItem.picture}
            srcSet={`${carouselItem.picture} 1x, ${carouselItem.picturesmall} 0.5x`}
            alt={carouselItem.alt}
            style={{
              borderRadius: "50%",
              height: "260px",
              width: "260px",
              marginTop: "40px",
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CarouselItem;
