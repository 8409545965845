import React, { useState, useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, Modal, Badge } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import {
  companyIndustryMap,
  offerStatuses,
  languages,
} from "bundles/common/utils/constants";
import { makeStyles } from "@mui/styles";
import SmartChurchBar from "bundles/common/components/SmartChurchBar";
import FilteringModal from "./FilteringModal";
import { be_regions, lu_regions } from "bundles/common/utils/constants";

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "920px",
  },
  filterButtonBox: {
    marginTop: "16px",
    display: "flex",
    columnGap: "8px",
  },
  filterButton: {
    padding: "4px 8px",
    border: "1px solid #F5F5F5",
    borderRadius: "24px",
    backgroundColor: "inherit",
    color: "#FFFFFF",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  badge: {
    "& .MuiBadge-badge": {
      marginRight: "4px",
    },
  },
}));

const AdvancedFiltering = ({
  onlyFavorites,
  setSelectedLanguages,
  setSelectedIndustries,
  setSelectedJobStatuses,
  setSelectedRegions,
  clearAdvancedFilters,
  queryText,
  setQueryText,
  setOnlyFavorites,
  fetchRecords,
}) => {
  const intl = useIntl();

  const classes = useStyles();

  const [filteringModalOpened, setFilteringModalOpened] = useState(false);
  const [selectedSection, setSelectedSection] = useState(null);

  const query = useQuery();
  const isInitialMount = useRef(true);
  const [preSetFilters, setPreSetFilters] = useState({});

  const sections = useMemo(() => {
    return [
      {
        code: "language",
        label: "candidate.profile.skills.lang",
        values: languages.map((lang) => {
          return {
            code: lang,
            label: `languages.${lang}`,
          };
        }),
        display: "box",
      },
      {
        code: "sector",
        label: "candidate.desired_sector",
        values: companyIndustryMap.map((industry) => {
          return {
            code: industry,
            label: `company.company_industry.${industry}`,
          };
        }),
        display: "box",
      },
      {
        code: "job_status",
        label: "candidate.desired_job_type",
        values: offerStatuses.map((offerStatus) => {
          return {
            code: offerStatus.code,
            label: `candidate.status_map.${offerStatus.code}`,
          };
        }),
        display: "box",
      },
      {
        code: "region",
        label: "regions.location",
        display: "select_region",
        values: be_regions.concat(lu_regions).map((region) => {
          return {
            code: region,
            label: intl.formatMessage({ id: `regions.${region}` }),
          };
        }),
      },
    ];
  }, [intl]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      const sectorFromUrl = query.get("selectedSector");
      const regionFromUrl = query.get("selectedRegion");

      let newFilters = {};
      if (sectorFromUrl) newFilters["sector"] = [sectorFromUrl];
      if (regionFromUrl) newFilters["region"] = regionFromUrl.split(",");

      setSelectedFilters(prevFilters => ({ ...prevFilters, ...newFilters }));
    }
  }, [query, sections]);

  const [selectedFilters, setSelectedFilters] = useState(() => {
    let initialFilters = {};
    sections.forEach((section) => {
      initialFilters[section.code] = [];
    });
    return initialFilters;
  });

  const hasActiveFilters = Object.values(selectedFilters || {}).flat().length > 0 ||
                          Object.keys(preSetFilters).some(key => preSetFilters[key]);

  const openFilteringModal = (section) => {
    setSelectedSection(section);
    setFilteringModalOpened(true);
  };

  const clearAll = () => {
    let json = {};
    sections.forEach((section) => {
      json[section.code] = [];
    });
    setSelectedFilters(json);
    clearAdvancedFilters();
  };

  const handleFilteringModalSearch = () => {
    Object.entries(selectedFilters || {}).forEach(([section, filters]) => {
      let newFiltersArray = [];

      filters.forEach((filter) => {
        if (Array.isArray(filter)) {
          newFiltersArray = [...newFiltersArray, ...filter];
        } else {
          newFiltersArray.push(filter);
        }
      });
      switch (section) {
      case "language":
        setSelectedLanguages(newFiltersArray);
        break;
      case "sector":
        setSelectedIndustries(newFiltersArray);
        break;
      case "job_status":
        setSelectedJobStatuses(newFiltersArray);
        break;
      case "region":
        setSelectedRegions(newFiltersArray);
        break;
      }
    });
  };

  useEffect(() => {
    handleFilteringModalSearch();
  }, [Object.values(selectedFilters || {}).flat()?.length]);

  return (
    <>
      <Box className={classes.container} id="sh-advanced-filtering">
        <Box>
          <SmartChurchBar
            queryText={queryText}
            setQueryText={setQueryText}
            fetchRecords={fetchRecords}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            sections={sections}
            onlyFavorites={onlyFavorites}
            setOnlyFavorites={setOnlyFavorites}
            placeholder="landing_recruiter.searched_jobs"
          />
        </Box>
        <Box className={classes.filterButtonBox}>
          {sections.map((section) => (
            <Badge
              key={`filter-button-${section.code}`}
              color="secondary"
              badgeContent={selectedFilters[section.code]?.length || 0}
              className={classes.badge}
            >
              <Button
                className={classes.filterButton}
                onClick={() => openFilteringModal(section.code)}
              >
                <FormattedMessage id={section.label} />
              </Button>
            </Badge>
          ))}
          {hasActiveFilters && (
            <Button
              variant="textUnderline"
              onClick={clearAll}
              style={{ color: "white", padding: "0px" }}
            >
              <FormattedMessage id="job_search.clear_filters" />
            </Button>
          )}
        </Box>
      </Box>
      <Modal
        open={filteringModalOpened}
        onClose={() => setFilteringModalOpened(false)}
        aria-labelledby="modal-filtering-title"
        aria-describedby="modal-filtering-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FilteringModal
          sections={sections}
          selectedSection={selectedSection}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          onlyFavorites={onlyFavorites}
          setOnlyFavorites={setOnlyFavorites}
          closeModal={() => setFilteringModalOpened(false)}
        />
      </Modal>
    </>
  );
};

export default AdvancedFiltering;
