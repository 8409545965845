import React, { useState } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  ListItemText,
  Checkbox,
  Box,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { useTheme, makeStyles } from "@mui/styles";
import { be_regions, lu_regions } from "bundles/common/utils/constants";
import { FormattedMessage, useIntl } from "react-intl";
import { capitalize } from "bundles/common/utils/utils";

const SelectRegion = ({
  handleInputValue,
  variant,
  labelId,
  required,
  className = "",
  multiple = false,
  list = false,
  size,
  value,
  ...props
}) => {
  const intl = useIntl();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const useStyles = makeStyles((theme) => ({
    desktopSelect: {
      border: 0,

      "& fieldset": {
        border: 0,
      },
    },
    mobileSelect: {
      borderRadius: "20px !important",
    },
    menuItem: {
      height: isDesktop ? "36px" : "32px",
      padding: isDesktop ? "0px" : "16px",
      "&.Mui-selected": {
        backgroundColor: "white !important",
      },
      "&:hover": {
        backgroundColor: `${theme.palette.common.sh_lightgrey} !important`,
      },
    },
    menuItemOption: { paddingLeft: "32px !important" },
  }));
  const classes = useStyles();

  const initialBelgiumOptions = {
    country: "belgium",
    regions: be_regions,
    isOpened: true,
  };

  const initialLuxembourgOptions = {
    country: "luxembourg",
    regions: lu_regions,
    isOpened: false,
  };

  const [computedBelgiumOptions, setComputedBelgiumOptions] = useState(
    initialBelgiumOptions
  );
  const [computedLuxembourgOptions, setComputedLuxembourgOptions] = useState(
    initialLuxembourgOptions
  );

  const handleToggleCountry = (e, country) => {
    e.stopPropagation();
    if (country == "belgium") {
      setComputedBelgiumOptions({
        ...computedBelgiumOptions,
        isOpened: !computedBelgiumOptions.isOpened,
      });
    } else if (country == "luxembourg") {
      setComputedLuxembourgOptions({
        ...computedLuxembourgOptions,
        isOpened: !computedLuxembourgOptions.isOpened,
      });
    }
  };

  const handleClick = (e) => {
    let values = e.target.value;

    handleUpdate(values);
  };

  const handleMobileInput = (region) => {
    if (value.includes(region)) {
      handleUpdate(value.filter((a) => a != region));
    } else {
      handleUpdate(value.concat(region));
    }
  };

  const handleUpdate = (updatedValues) => {
    let allValues = updatedValues.filter((v) => !v.includes("country"));

    // Handling select all
    let countryValues = updatedValues.filter((v) => v.includes("country"));
    countryValues.forEach((countryValue) => {
      let country = countryValue.split("-")[1];
      let regions = [];
      if (country == "belgium") {
        if (isAllSelected(be_regions)) {
          allValues = allValues.filter(
            (v) => !computedBelgiumOptions.regions.includes(v)
          );
        } else {
          regions = computedBelgiumOptions.regions;
        }
      } else if (country == "luxembourg") {
        if (isAllSelected(lu_regions)) {
          allValues = allValues.filter(
            (v) => !computedLuxembourgOptions.regions.includes(v)
          );
        } else {
          regions = computedLuxembourgOptions.regions;
        }
      }
      allValues = allValues.concat(regions);
    });

    handleInputValue([...new Set(allValues)]);
  };

  function isAllSelected(countryRegions) {
    return countryRegions.every((region) => value?.includes(region));
  }

  function areSomeSelected(countryRegions) {
    return (
      !isAllSelected(countryRegions) &&
      countryRegions.some((region) => value?.includes(region))
    );
  }

  return !list ? (
    <FormControl
      fullWidth
      required={required || false}
      variant={variant}
      className={className}
      size={size}
    >
      <Select
        {...props}
        value={value || []}
        label={<FormattedMessage id={labelId} />}
        onBlur={handleClick}
        onChange={handleClick}
        multiple={multiple}
        labelId={labelId}
        className={isDesktop ? classes.desktopSelect : classes.mobileSelect}
        IconComponent={() => null}
        inputProps={{ style: { padding: "0 !important" } }}
        MenuProps={{
          style: { maxWidth: 0, maxHeight: 300, position: "absolute" },
          disableScrollLock: true,
          autoFocus: false,
        }}
      >
        {/* BELGIUM */}
        <MenuItem
          value="country-belgium"
          label={intl.formatMessage({
            id: "countries.belgium",
          })}
          style={{ display: "flex" }}
          key="country-belgium"
          className={`${classes.menuItem} ${classes.menuItemHeader}`}
        >
          <Checkbox
            checked={isAllSelected(be_regions)}
            indeterminate={areSomeSelected(be_regions)}
          />
          <ListItemText
            primary={intl.formatMessage({
              id: "countries.belgium",
            })}
          />
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            onClick={(e) => handleToggleCountry(e, "belgium")}
            aria-label="close"
          >
            {computedBelgiumOptions.isOpened ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </MenuItem>

        {computedBelgiumOptions.isOpened &&
          computedBelgiumOptions.regions.map((region) => {
            return (
              <MenuItem
                key={region}
                value={region}
                className={`${classes.menuItem} ${classes.menuItemOption}`}
              >
                <Checkbox checked={value?.includes(region)} />
                <ListItemText
                  primary={intl.formatMessage({ id: `regions.${region}` })}
                />
              </MenuItem>
            );
          })}

        {/* LUXEMBOURG */}
        <MenuItem
          value="country-luxembourg"
          label={intl.formatMessage({
            id: "countries.luxembourg",
          })}
          style={{ display: "flex" }}
          key="country-luxembourg"
          className={`${classes.menuItem} ${classes.menuItemHeader}`}
        >
          <Checkbox
            checked={isAllSelected(lu_regions)}
            indeterminate={areSomeSelected(lu_regions)}
          />
          <ListItemText
            primary={intl.formatMessage({
              id: "countries.luxembourg",
            })}
          />
          <IconButton
            size="small"
            edge="start"
            color="inherit"
            onClick={(e) => handleToggleCountry(e, "luxembourg")}
            aria-label="close"
          >
            {computedLuxembourgOptions.isOpened ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </IconButton>
        </MenuItem>

        {computedLuxembourgOptions.isOpened &&
          computedLuxembourgOptions.regions.map((region) => {
            return (
              <MenuItem
                key={region}
                value={region}
                className={`${classes.menuItem} ${classes.menuItemOption}`}
              >
                <Checkbox checked={value?.includes(region)} />
                <ListItemText
                  primary={intl.formatMessage({ id: `regions.${region}` })}
                />
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  ) : (
    <>
      {/* BELGIUM */}
      <MenuItem
        onClick={() => handleMobileInput("country-belgium")}
        value="country-belgium"
        label={intl.formatMessage({
          id: "countries.belgium",
        })}
        style={{ display: "flex" }}
        key="country-belgium"
        className={`${classes.menuItem} ${classes.menuItemHeader}`}
      >
        <Checkbox
          checked={isAllSelected(be_regions)}
          indeterminate={areSomeSelected(be_regions)}
        />
        <ListItemText
          primary={intl.formatMessage({
            id: "countries.belgium",
          })}
        />
        <Box
          style={{
            flexGrow: 50,
            display: "flex",
            justifyContent: "end",
            cursor: "pointer",
          }}
          onClick={(e) => handleToggleCountry(e, "belgium")}
        >
          {computedBelgiumOptions.isOpened ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </MenuItem>
      {computedBelgiumOptions.isOpened &&
        computedBelgiumOptions.regions.map((region) => {
          return (
            <MenuItem
              onClick={() => handleMobileInput(region)}
              key={region}
              value={region}
              className={`${classes.menuItem} ${classes.menuItemOption}`}
            >
              <Checkbox checked={value?.includes(region)} />
              <ListItemText
                primary={intl.formatMessage({ id: `regions.${region}` })}
              />
            </MenuItem>
          );
        })}
      {/* LUXEMBOURG */}
      <MenuItem
        onClick={() => handleMobileInput("country-luxembourg")}
        value="country-luxembourg"
        label={intl.formatMessage({
          id: "countries.luxembourg",
        })}
        style={{ display: "flex" }}
        key="country-luxembourg"
        className={`${classes.menuItem} ${classes.menuItemHeader}`}
      >
        <Checkbox
          checked={isAllSelected(lu_regions)}
          indeterminate={areSomeSelected(lu_regions)}
        />
        <ListItemText
          primary={intl.formatMessage({
            id: "countries.luxembourg",
          })}
        />
      </MenuItem>
      {computedLuxembourgOptions.isOpened &&
        computedLuxembourgOptions.regions.map((region) => {
          return (
            <MenuItem
              onClick={() => handleMobileInput(region)}
              key={region}
              value={region}
              className={`${classes.menuItem} ${classes.menuItemOption}`}
            >
              <Checkbox checked={value?.includes(region)} />
              <ListItemText
                primary={intl.formatMessage({ id: `regions.${region}` })}
              />
            </MenuItem>
          );
        })}
    </>
  );
};

export default SelectRegion;
