import React from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Box,
  Divider,
  TextField,
  InputAdornment,
  Button,
  Typography,
} from "@mui/material";
import { SearchOutlined, LocationOnOutlined } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import SelectRegion from "../SelectRegion";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    height: "60px",
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 1px 1px 0px #00000040",
  },
  queryInput: { height: "100%" },
  divider: {
    paddingBottom: "8px",
    paddingTop: "8px",
    height: "calc(100% - 16px) !important",
  },
  locationSearchContainer: {
    width: "calc(50% - 1px)",
    display: "flex",
    alignItems: "center",
    paddingRight: "16px",
  },
  searchButton: {
    paddingLeft: "32px !important",
    paddingRight: "32px !important",
  },
}));

const ChurchBar = ({
  queryText,
  setQueryText,
  fetchRecords,
  placeholder,
  selectedRegions,
  setSelectedRegions,
  searchRegion = true,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const currentRecruiter = useSelector((state) => state.current_recruiter);
  const isRecruiter = currentRecruiter != null;

  return (
    <Box display="flex" className={classes.container}>
      <Box
        width={searchRegion ? "calc(50% - 1px)" : "99%"}
        height="100%"
        display="flex"
        style={{ paddingLeft: "16px" }}
      >
        <TextField
          style={{ width: "100%", height: "100%" }}
          id="landing_recruiter.searched_candidates"
          label=""
          value={queryText}
          placeholder={intl.formatMessage({ id: placeholder })}
          onChange={(e) => setQueryText(e.target.value)}
          InputProps={{
            className: classes.queryInput,
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              fetchRecords();
            }
          }}
          variant="standard"
        />
      </Box>
      {searchRegion && (
        <>
          <Box>
            <Divider
              orientation="vertical"
              variant="middle"
              className={classes.divider}
            />
          </Box>
          <Box className={classes.locationSearchContainer}>
            <SelectRegion
              required
              multiple
              labelId="landing_recruiter.searched_location"
              value={selectedRegions}
              handleInputValue={(values) => setSelectedRegions(values)}
              displayEmpty={true}
              renderValue={(selected) => {
                return selected.length > 0 ? (
                  selected
                    ?.map((value) =>
                      intl.formatMessage({ id: `regions.${value}` })
                    )
                    ?.join(", ")
                ) : (
                  <Typography style={{ color: "#C7C7CD" }}>
                    <FormattedMessage
                      id={"landing_recruiter.searched_location"}
                    />
                  </Typography>
                );
              }}
              startAdornment={
                <InputAdornment position="start">
                  <LocationOnOutlined />
                </InputAdornment>
              }
            />
            <Button
              variant="rounded"
              color={isRecruiter ? "secondaryContained" : "primaryContained"}
              className={classes.searchButton}
              onClick={() => fetchRecords()}
            >
              <FormattedMessage id="search" />
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ChurchBar;
